import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '../store';
import NProgress from 'nprogress/nprogress';

Vue.use(VueRouter);

const routes = [
  {
    path: '/index.html',
    redirect: {
      name: 'dashboardAdmin',
    },
  },
  {
    path: '/',
    redirect: {
      name: 'dashboardAdmin',
    },
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue'),
  },
  {
    path: '/admin',
    meta: {
      authorize: ['admin'],
    },
    redirect: {
      name: 'dashboardAdmin',
    },
    component: () => import(/* webpackChunkName: "root" */ '../views/Root.vue'),
    children: [
      {
        path: '/',
        redirect: {
          name: 'dashboardAdmin',
        },
        meta: {
          authorize: ['admin'],
        },
        component: () =>
          import(
            /* webpackChunkName: "about" */
            '../views/admin/Dashboard.vue'
          ),
        children: [
          {
            meta: {
              authorize: ['admin'],
            },
            path: '/dashboard',
            name: 'dashboardAdmin',
            component: () => import(/* webpackChunkName: "about" */ '../views/admin/Home.vue'),
          },
          {
            meta: {
              authorize: ['admin'],
            },
            path: '/income',
            name: 'income',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/admin/Income.vue'
              ),
          },
          {
            meta: {
              authorize: ['admin'],
            },
            path: '/booking',
            name: 'booking',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/admin/Booking.vue'
              ),
          },
          {
            meta: {
              authorize: ['admin'],
            },
            path: '/newsletter',
            name: 'newsletter',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/admin/Newsletter.vue'
              ),
          },
          {
            path: '/spend',
            meta: {
              authorize: ['admin'],
            },
            name: 'spend',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/admin/Spend.vue'
              ),
          },
          {
            meta: {
              authorize: ['admin'],
            },
            path: '/users',
            name: 'users',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/admin/Users.vue'
              ),
          },
          {
            meta: {
              authorize: ['admin'],
            },
            path: '/userpayments/:customerId/:customerName/:lote',
            name: 'userpayments',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/admin/UserMaintenancePayments.vue'
              ),
            props: true,
          },
          {
            meta: {
              authorize: ['admin'],
            },
            path: '/notifications',
            name: 'notifications',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/admin/Notifications.vue'
              ),
          },
          {
            meta: {
              authorize: ['admin'],
            },
            path: '/settings',
            name: 'settings',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/admin/Settings.vue'
              ),
          },
          {
            meta: {
              authorize: ['admin'],
            },
            path: '/reports',
            name: 'reports',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/admin/Reports.vue'
              ),
          },
          {
            meta: {
              authorize: ['admin'],
            },
            path: '/tickets',
            name: 'tickets',
            component: () =>
              import(
                '../views/admin/Tickets.vue'
              ),
          },
          {
            meta: {
              authorize: ['admin'],
            },
            path: '/tickethistory/:ticketId',
            props: true, 
            name: 'tickethistory',
            component: () =>
              import(                
                '../views/admin/Tickethistory.vue'
              ),
          },
          {
            meta: {
              authorize: ['admin'],
            },
            path: '/userreportform',
            name: 'userreportform',
            component: () =>
              import(
                '../views/admin/UserReportForm.vue'
              ),
          },
          {
            meta: {
              authorize: ['admin'],              
            },
            path: '/userreport',
            name: 'userreport',
            component: () =>
              import(
                '../views/admin/UserReport.vue'
              ),
            props: true,
          },
          {
            meta: {
              authorize: ['admin'],
            },
            path: '/rules',
            name: 'rules',
            component: () =>
              import(
                '../views/admin/Rulements.vue'
              ),
          },
        ],
      },
    ],
  },
  {
    path: '/user',
    redirect: {
      name: 'dashboardUser',
    },
    component: () => import(/* webpackChunkName: "root" */ '../views/Root.vue'),
    children: [
      {
        path: '/',
        redirect: {
          name: 'dashboardUser',
        },
        meta: {
          authorize: ['user'],
        },
        component: () => import(/* webpackChunkName: "about" */ '../views/user/Dashboard.vue'),
        children: [
          {
            path: '/dashboard',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'dashboardUser',
            component: () => import(/* webpackChunkName: "about" */ '../views/user/Home.vue'),
          },
          {
            path: '/notifications',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'notificationsUser',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/user/Notifications.vue'
              ),
          },
          {
            path: '/profile',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'profile',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/user/Profile.vue'
              ),
          },
          {
            path: '/financial',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'financial',
            component: () =>
              import(
                /* webpackChunkName: "payments" */
                '../views/user/Financial.vue'
              ),
          },
          {
            path: '/eventstopay',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'eventstopay',
            component: () =>
              import(
                /* webpackChunkName: "payments" */
                '../views/user/EventsTopay.vue'
              ),
          },
          {
            path: '/maintenancefees',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'maintenancefees',
            component: () =>
              import(
                /* webpackChunkName: "payments" */
                '../views/user/Maintenancefees.vue'
              ),
          },
          {
            path: '/mortgagepayments',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'mortgagepayments',
            component: () =>
              import(
                /* webpackChunkName: "payments" */
                '../views/user/Mortgagepayments.vue'
              ),
          },
          {
            path: '/spendinformation',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'spendinformation',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/user/SpendInformation.vue'
              ),
          },
          {
            path: '/bookingUser',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'bookingUser',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/user/Booking.vue'
              ),
          },
          {
            path: '/bookingPlaceInformation',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'bookingPlaceInformation',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/user/BookingPlaceInformation.vue'
              ),
          },
          {
            path: '/bookingdateselect',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'bookingdateselect',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/user/BookingDateSelect.vue'
              ),
          },
          {
            path: '/bookingthanks',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'bookingthanks',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/user/BookingThanks.vue'
              ),
          },
          {
            path: '/debtors',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'debtors',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/user/Debtors.vue'
              ),
          },
          {
            path: 'cardsuser',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'cardsuser',
            component: () => import(/* webpackChunkName: "about" */ '../views/user/Cards.vue'),
          },
          {
            path: '/newcardsuser',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'newcardsuser',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/user/NewCard.vue'
              ),
          },
          {
            path: '/events',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'events',
            component: () =>
              import(
                /* webpackChunkName: "about" */
                '../views/user/Events.vue'
              ),
          },
          {
            path: '/regulations',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'regulations',
            component: () =>
              import(
             
                '../views/user/Regulations.vue'
              ),
          },
          {
            path: '/rulement',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'rulement',
            component: () =>
              import(
             
                '../views/user/Rulement.vue'
              ),
          },
          {
            path: '/reglasuso',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'reglasuso',
            component: () =>
              import(
             
                '../views/user/ReglasUso.vue'
              ),
          },
          {
            path: '/asambleas',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'asambleas',
            component: () =>
              import(
             
                '../views/user/Asambleas.vue'
              ),
          },
          {
            path: '/mytickets',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'mytickets',
            component: () =>
              import(
               
                '../views/user/Tickets.vue'
              ),
          },
          {
            path: '/ticketview/:ticketId',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'ticketview',          
            props: true, 
            component: () =>
              import(
               
                '../views/user/TicketHistory.vue'
              ),
          },
          {
            path: '/newticket',
            meta: {
              authorize: ['user', 'admin'],
            },
            name: 'newticket',          
            props: true, 
            component: () =>
              import(
               
                '../views/user/TicketOpener.vue'
              ),
          },
        ],
      },
    ],
  },
  
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
});

// Before each route evaluates...
router.beforeEach(async (to, from, next) => {
  if (from.name !== null) {
    NProgress.start()
  }
  const authRequired = to.matched.some((route) => route.meta.authorize);
  console.log('authRequired '+authRequired);
  if (!authRequired) return next();

  const { authorize } = to.meta;
  console.log('authoriza '+ authorize);
  if (!authorize) return redirectToLogin();

  // If auth is required and the user is logged in...
  if (store.getters['session/loggedIn']) {
    // Validate the local user token...
    const permissions = await store.dispatch('session/validate');
    return permissions.role.some(role => authorize.indexOf(role) >= 0) ? next() : redirectToLogin();
  }

  redirectToLogin();
  function redirectToLogin() {
    // Pass the original route to the login component
    next({ name: 'login', query: { redirectFrom: to.fullPath } });
  }
});

router.beforeResolve(async (routeTo, routeFrom, next) => {
  try {
    for (const route of routeTo.matched) {
      await new Promise((resolve, reject) => {
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
            if (args.length) {
              if (routeFrom.name === args[0].name) {
                NProgress.done()
              }
              next(...args)
              reject(new Error('Redirected'))
            } else {
              resolve()
            }
          })
        } else {
          resolve()
        }
      })
    }
  } catch (error) {
    return
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})


export default router;
